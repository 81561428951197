.progress-bar {
  border-bottom: $progress-bar-height solid $progress-bar-bg;

  @each $color, $values in $theme-colors {
    &.bg-#{$color} {
      background-color: transparent !important; // stylelint-disable-line declaration-no-important
      border-bottom-color: theme-color($color);

      &::after {
        background-color: theme-color-light($color);
      }
    }
  }

  &::after {
    background-color: $progress-bg;
  }
}

.progress-bar-animated,
.progress-bar-striped {
  @each $color, $values in $theme-colors {
    &.bg-#{$color}::before {
      // stylelint-disable value-no-vendor-prefix
      background-image: repeating-radial-gradient(#{$progress-bar-height / 2} #{$progress-bar-height / 2}, theme-color-light($color), theme-color-light($color) #{$progress-bar-height / 2}, transparent #{$progress-bar-height / 2}, transparent 100%);
      background-image: -webkit-repeating-radial-gradient(#{$progress-bar-height / 2} #{$progress-bar-height / 2}, theme-color-light($color), theme-color-light($color) #{$progress-bar-height / 2}, transparent #{$progress-bar-height / 2}, transparent 100%);
      background-image: -moz-repeating-radial-gradient(#{$progress-bar-height / 2} #{$progress-bar-height / 2}, theme-color-light($color), theme-color-light($color) #{$progress-bar-height / 2}, transparent #{$progress-bar-height / 2}, transparent 100%);
      // stylelint-enable
    }
  }

  &::before {
    // stylelint-disable value-no-vendor-prefix
    background-image: repeating-radial-gradient(#{$progress-bar-height / 2} #{$progress-bar-height / 2}, #{$progress-bg}, #{$progress-bg} #{$progress-bar-height / 2}, transparent #{$progress-bar-height / 2}, transparent 100%);
    background-image: -webkit-repeating-radial-gradient(#{$progress-bar-height / 2} #{$progress-bar-height / 2}, #{$progress-bg}, #{$progress-bg} #{$progress-bar-height / 2}, transparent #{$progress-bar-height / 2}, transparent 100%);
    background-image: -moz-repeating-radial-gradient(#{$progress-bar-height / 2} #{$progress-bar-height / 2}, #{$progress-bg}, #{$progress-bg} #{$progress-bar-height / 2}, transparent #{$progress-bar-height / 2}, transparent 100%);
    // stylelint-enable
  }
}

.progress-bar-indeterminate {
  border-bottom-color: $progress-bg;

  @each $color, $values in $theme-colors {
    &.bg-#{$color} {
      border-bottom-color: theme-color-light($color);

      &::after,
      &::before {
        background-color: theme-color($color);
      }

      &::before {
        background-image: none;
      }
    }
  }

  &::after,
  &::before {
    background-color: $progress-bar-bg;
  }
}
