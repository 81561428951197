.alert {
  @include border-radius($alert-border-radius);

  border: 0;
  display: block;
  margin-bottom: $alert-margin-y;
  padding: $alert-padding-y $alert-padding-x;
  position: relative;
}

// Dismissible

.alert-dismissible {
  padding-right: ($alert-padding-x * 2 + $close-font-size);

  .close {
    padding: ($alert-padding-y - ($close-font-size - $font-size-base * $line-height-base) / 2) $alert-padding-x;
    position: absolute;
    top: 0;
    right: 0;
  }
}

// Misc
.alert-link {
  font-weight: $alert-link-font-weight;
}
